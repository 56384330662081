import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageContext } from '../types/pageContext';
import { IContentfulEntry } from '../types/contentfulContentTypes';
import ComponentInternalType from '../components/contentful/ComponentInternalType';

export const query = graphql`
  query PageProductLineTemplate($id: String!) {
    contentfulPageProductLine(id: { eq: $id }) {
      ...PageProductLineFragment
    }
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
    contentfulGlobalOptions {
      ...GlobalFragment
    }
    contentfulComponentSiteHeader {
      logo {
        ...AssetFragment
      }
      userNavigation {
        ...NavigationFragment
      }
      mainNavigation {
        ...NavigationFragment
      }
      searchCta {
        ...LinkFragment
      }
    }
    contentfulComponentSiteFooter {
      exclusiveToRetailer {
        ...ContentFlexibleFragment
      }
      legalCopy {
        ...ContentFlexibleFragment
      }
      logo {
        ...AssetFragment
      }
      menus {
        ...NavigationFragment
      }
      signUpForm {
        ...FormFragment
      }
      socials {
        ...NavigationFragment
      }
    }
  }
`;
const FlexiblePageTemplate: React.FC<
  PageProps<Queries.PageProductLineTemplateQuery, PageContext>
> = (props) => {
  const { data } = props;
  const { contentfulPageProductLine } = data;
  return (
    <>
      {contentfulPageProductLine?.sections &&
        contentfulPageProductLine?.sections?.map((section, index: number) => (
          <ComponentInternalType
            key={index}
            component={section as IContentfulEntry}
          />
        ))}
    </>
  );
};

export default FlexiblePageTemplate;
